import { DropdownItem } from '@apeiron/common'
import { PlanetClass } from '@apeiron/planet'
import { DownloadMap, MayNull, PageInfoLite } from '@apeiron/temp'
import {
  BattleDemoFragmentType,
  BattleDemoRewardSetType
} from '@src/constants/battleDemo'
import { Guild } from '@src/types/guild'

type BattleDemoRankPlanet = {
  icon: string
  id: string
  planetClass: PlanetClass
}

type BattleDemoRankUser = {
  name: string
  tag: number
  walletAddress: string
}

type BattleDemoLeaderboardSummary = {
  end: string
  start: string
}

type BattleDemoSoloPvELeaderboardRecord = {
  pageInfo: PageInfoLite
  playerRank?: MayNull<BattleDemoSoloPvERank>
  ranks: BattleDemoSoloPvERank[]
}

export type BattleDemoNews = {
  date: string
  content: string
  thumbnail?: string
  banner?: string
  title: string
  description: string
}

export type BattleDemoNewsList = {
  news: BattleDemoNews[]
  patches: BattleDemoNews[]
}

export type BattleDemoConfig = {
  downloadMap: DownloadMap
  news: BattleDemoNewsList
}

export type BattleDemoFragment = {
  burn: number
  burnRatio: number
  id: string
  name: string
  openSeaUrl: string
  total: number
  type: BattleDemoFragmentType
}

export type BattleDemoRewardGroup = {
  rankMax: number
  rankMin: number
  rewardsBasic: BattleDemoRewardLite[]
  rewardsBonus: BattleDemoRewardLite[]
}

export type BattleDemoSeason = DropdownItem & {
  active: boolean
  end: string
  start: string
  rewardGroups: MayNull<BattleDemoRewardGroup[]>
}

export type BattleDemoSoloPvERank = {
  id: string
  planet: BattleDemoRankPlanet
  rank: number
  user: BattleDemoRankUser
  score: number
}

export type BattleDemoSoloPvPRank = {
  id: string
  matchMakingRate: number
  rank: number
  user: BattleDemoRankUser
  totalStar: number
}

export type BattleDemoGuildPvPRank = {
  guild: Guild
  id: string
  matchMakingRate: number
  rank: number
  totalStar: number
}

export type BattleDemoSoloPvELeaderboard = {
  summary: BattleDemoLeaderboardSummary
  record: BattleDemoSoloPvELeaderboardRecord
}

export type BattleDemoSoloPvPLeaderboard = {
  playerRank?: MayNull<BattleDemoSoloPvPRank>
  ranks: BattleDemoSoloPvPRank[]
}

export type BattleDemoGuildPvPLeaderboard = {
  playerRank?: MayNull<BattleDemoGuildPvPRank>
  ranks: BattleDemoGuildPvPRank[]
}

export type BattleDemoAboutCard = {
  description: string
  image: string
  title: string
  imageAspectRatio: number
}

export type BattleDemoAbout = {
  apeironTitle: string
  content: BattleDemoAboutCard[]
  title: string
}

export type BattleDemoReward = BattleDemoRewardLite & {
  image: string
  claimDatatype: ClaimableCurrencyDatatype
  source: string
}

export type BattleDemoRewardSet = {
  items: BattleDemoReward[]
  type: BattleDemoRewardSetType
}

export type BattleDemoRewardSetPool = {
  [key in BattleDemoRewardSetType]?: BattleDemoReward[]
}

export type BattleDemoRewardLite = {
  name: string
  value: number
}

// game server currencies
export enum ClaimableCurrencyDatatype {
  Aprm = 'Aprm',
  Animus = 'Animus',
  DoodHalo = 'Dood_Halo',
  DoodelicWings = 'Doodelic_Wings'
}
