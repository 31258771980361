import { Role, RoleMap } from '@apeiron/temp'
import { FeatureFlagType } from '@src/constants/featureFlag'
import { GIFT_CODE_ROUTE } from '@src/deprecated/giftCode/giftCode'
import featureAllowed from '@src/util/apeiron/featureAllowed'

// enum - route
export enum ROUTE {
  ACCOUNT_SETTING = '/user/setting/',
  ADEPTUS_PROGRAM = '/user/adeptus/program/',
  BATTLE_DEMO_BATTLE_PASS = '/battle-demo/battle-pass/',
  BATTLE_DEMO_BEGINNERS_GUIDE = '/battle-demo/beginners-guide/',
  BATTLE_DEMO_ECONOMY = '/battle-demo/economy/',
  BATTLE_DEMO_GAME = '/battle-demo/game/',
  BATTLE_DEMO_GUILD_WAR_PVP = '/battle-demo/guild-war-pvp/',
  BATTLE_DEMO_NEWS = '/battle-demo/news/',
  BATTLE_DEMO_SOLO_PVE = '/battle-demo/solo-pve/',
  BATTLE_DEMO_SOLO_PVP = '/battle-demo/solo-pvp/',
  BATTLE_DEMO_START_GAME = '/battle-demo/start/',
  CALLBACK_GAME = '/callback/game/',
  CALLBACK_SSO = '/callback/sso/',
  SANCTUARY_CONJUNCTION = '/sanctuary/conjunction/',
  SANCTUARY_ATTACH_APOSTLE = '/sanctuary/apostle-attach/',
  SANCTUARY_REFORGE_APOSTLE = '/sanctuary/apostle-reforge/',
  SANCTUARY_FORGE_APOSTLE = '/sanctuary/forge-apostle/',
  SANCTUARY_FORGE_APOSTLE_TICKET = '/sanctuary/forge-apostle/ticket/',
  DEBUG_VERSION = '/debug/version/',
  EXPEDITION = '/expedition/',
  FAVORITE_APOSTLE_DETAIL = '/user/favorite/apostle/detail/',
  FAVORITE_APOSTLE_LIST = '/user/favorite/apostle/list/',
  FAVORITE_GODIVERSE_DETAIL = '/user/favorite/godiverse/detail/',
  FAVORITE_GODIVERSE_LIST = '/user/favorite/godiverse/list/',
  FAVORITE_PLANET_DETAIL = '/user/favorite/planet/detail/',
  FAVORITE_PLANET_LIST = '/user/favorite/planet/list/',
  FAVORITE_STAR_DETAIL = '/user/favorite/star/detail/',
  FAVORITE_STAR_LIST = '/user/favorite/star/list/',
  FAVORITE_STAR_MANAGE = '/user/favorite/star/manage/',
  GODIVERSE_MANAGE_ATTACHMENT = '/godiverse/manage-attachment/',
  GODIVERSE_MANAGE_ORBITAL_TRACK = '/godiverse/manage-orbital-track/',
  HOME = '/',
  INVENTORY_APOSTLE_DETAIL = '/user/inventory/apostle/detail/',
  INVENTORY_APOSTLE_HISTORY = '/user/inventory/apostle/history/',
  INVENTORY_APOSTLE_LIST = '/user/inventory/apostle/list/',
  INVENTORY_GODIVERSE_DETAIL = '/user/inventory/godiverse/detail/',
  INVENTORY_GODIVERSE_LIST = '/user/inventory/godiverse/list/',
  INVENTORY_ORBITAL_TRACK_ORBITING = '/user/inventory/orbital-track/orbiting/',
  INVENTORY_ORBITAL_TRACK_RENTAL = '/user/inventory/orbital-track/rental/',
  INVENTORY_PLANET_DETAIL = '/user/inventory/planet/detail/',
  INVENTORY_PLANET_HISTORY = '/user/inventory/planet/history/',
  INVENTORY_PLANET_LIST = '/user/inventory/planet/list/',
  INVENTORY_PLANET_SEED_LIST = '/user/inventory/planet/seed-list/',
  INVENTORY_STAR_DETAIL = '/user/inventory/star/detail/',
  INVENTORY_STAR_LIST = '/user/inventory/star/list/',
  INVENTORY_STAR_MANAGE = '/user/inventory/star/manage/',
  INVENTORY_BOUNTY_ACTIVE_LIST = '/user/inventory/bounty/active-bounties/',
  INVENTORY_BOUNTY_CLOSED_LIST = '/user/inventory/bounty/closed-bounties/',
  MAINTENANCE = '/maintenance/',
  MARKETPLACE_APOSTLE_ACTIVITY_LIST = '/emporium/apostle/activity/?activityType=FulfillBounty&activityType=Bought',
  MARKETPLACE_APOSTLE_DETAIL = '/emporium/apostle/detail/',
  MARKETPLACE_APOSTLE_BOUNTY = '/emporium/apostle/bounties/',
  MARKETPLACE_APOSTLE_LIST = '/emporium/apostle/list/?nftListingStatus=Listing',
  MARKETPLACE_GODIVERSE_ACTIVITY_LIST = '/emporium/godiverse/activity/',
  MARKETPLACE_GODIVERSE_DETAIL = '/emporium/godiverse/detail/',
  MARKETPLACE_GODIVERSE_LIST = '/emporium/godiverse/list/',
  MARKETPLACE_ORBITAL_TRACK_ACTIVITY_LIST = '/emporium/orbital-track/activity/',
  MARKETPLACE_ORBITAL_TRACK_LIST = '/emporium/orbital-track/list/',
  MARKETPLACE_PLANET_ACTIVITY_LIST = '/emporium/planet/activity/',
  MARKETPLACE_PLANET_DETAIL = '/emporium/planet/detail/',
  MARKETPLACE_PLANET_LIST = '/emporium/planet/list/?nftListingStatus=Listing',
  MARKETPLACE_STAR_ACTIVITY_LIST = '/emporium/star/activity/',
  MARKETPLACE_STAR_DETAIL = '/emporium/star/detail/',
  MARKETPLACE_STAR_LIST = '/emporium/star/list/?nftListingStatus=Listing',
  MARKETPLACE_STAR_MANAGE = '/emporium/star/manage/',
  NONE = '',
  NOT_FOUND = '/404/',
  OTHER_INVENTORY_APOSTLE_DETAIL = '/other-user/inventory/apostle/detail/',
  OTHER_INVENTORY_APOSTLE_LIST = '/other-user/inventory/apostle/list/',
  OTHER_INVENTORY_GODIVERSE_DETAIL = '/other-user/inventory/godiverse/detail/',
  OTHER_INVENTORY_GODIVERSE_LIST = '/other-user/inventory/godiverse/list/',
  OTHER_INVENTORY_PLANET_DETAIL = '/other-user/inventory/planet/detail/',
  OTHER_INVENTORY_PLANET_LIST = '/other-user/inventory/planet/list/',
  OTHER_INVENTORY_STAR_DETAIL = '/other-user/inventory/star/detail/',
  OTHER_INVENTORY_STAR_LIST = '/other-user/inventory/star/list/',
  OTHER_INVENTORY_STAR_MANAGE = '/other-user/inventory/star/manage/',
  REWARD_DAILY_CHECK_IN = '/user/reward/check-in/',
  REWARD_LIST = '/user/reward/list/',
  REWARD_SHOP = '/user/reward/shop/',
  REWARD_VESTING = '/user/reward/vesting/',
  LP_STAKE = '/stake/'
}

const TEMPORARY_ROLE_ACCESS_BLOCK = {
  ...(featureAllowed(FeatureFlagType.ShowBattleDemoNews)
    ? {}
    : {
        [ROUTE.BATTLE_DEMO_NEWS]: Role.Restricted
      })
}

// const - route accessibility by role
export const ROLE_ACCESS: RoleMap<ROUTE> = {
  [ROUTE.ACCOUNT_SETTING]: Role.AnyOwner,
  [ROUTE.ADEPTUS_PROGRAM]: Role.SuperUser,
  [ROUTE.BATTLE_DEMO_BATTLE_PASS]: Role.AnyOwner,
  [ROUTE.FAVORITE_GODIVERSE_LIST]: Role.AnyOwner,
  [ROUTE.FAVORITE_PLANET_LIST]: Role.AnyOwner,
  [ROUTE.FAVORITE_STAR_LIST]: Role.AnyOwner,
  [ROUTE.GODIVERSE_MANAGE_ATTACHMENT]: Role.AnyOwner,
  [ROUTE.GODIVERSE_MANAGE_ORBITAL_TRACK]: Role.AnyOwner,
  [ROUTE.INVENTORY_APOSTLE_HISTORY]: Role.AnyOwner,
  [ROUTE.INVENTORY_APOSTLE_LIST]: Role.AnyOwner,
  [ROUTE.INVENTORY_BOUNTY_ACTIVE_LIST]: Role.AnyOwner,
  [ROUTE.INVENTORY_BOUNTY_CLOSED_LIST]: Role.AnyOwner,
  [ROUTE.INVENTORY_GODIVERSE_LIST]: Role.AnyOwner,
  [ROUTE.INVENTORY_ORBITAL_TRACK_ORBITING]: Role.AnyOwner,
  [ROUTE.INVENTORY_ORBITAL_TRACK_RENTAL]: Role.AnyOwner,
  [ROUTE.INVENTORY_PLANET_HISTORY]: Role.AnyOwner,
  [ROUTE.INVENTORY_PLANET_LIST]: Role.AnyOwner,
  [ROUTE.INVENTORY_STAR_LIST]: Role.AnyOwner,
  [ROUTE.REWARD_DAILY_CHECK_IN]: Role.AnyOwner,
  [ROUTE.REWARD_LIST]: Role.AnyOwner,
  [ROUTE.REWARD_VESTING]: Role.AnyOwner,
  [ROUTE.SANCTUARY_ATTACH_APOSTLE]: Role.AnyOwner,
  [ROUTE.SANCTUARY_REFORGE_APOSTLE]: Role.AnyOwner,
  [ROUTE.SANCTUARY_FORGE_APOSTLE]: Role.AnyOwner,
  [ROUTE.SANCTUARY_FORGE_APOSTLE_TICKET]: Role.AnyOwner,

  /** @deprecated - will be moved to web-store */
  [GIFT_CODE_ROUTE as ROUTE]: Role.AnyOwner,
  ...TEMPORARY_ROLE_ACCESS_BLOCK
}

export const ROUTE_HISTORY_LIMIT = 10
