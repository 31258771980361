import { usePath } from '@apeiron/ui'
import { WhiteList } from '@src/constants/page'
import { NextSeo } from 'next-seo'
import { FC } from 'react'

const OgDataNoIndex: FC<Props> = () => {
  const { noIndex } = usePath(WhiteList)

  return noIndex ? <NextSeo noindex /> : <></>
}

type Props = {}

export default OgDataNoIndex
