import { NextImage, useAccountContext } from '@apeiron/temp'
import { usePath } from '@apeiron/ui'
import { styled } from '@mui/material'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {
  MENU_HIDDEN_CONDITION,
  MENU_ITEM_ACTION,
  MENU_ITEM_CONFIG
} from '@src/constants/menu'
import { WhiteList } from '@src/constants/page'
import { MenuItem } from '@src/types/menu'
import Link from 'next/link'
import * as R from 'ramda'
import { FC, MouseEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const ListContainer = styled('div')`
  width: 260px;
  height: auto;
  aspect-ratio: 4.5;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14px;

  & .MuiListItemButton-root.Mui-selected {
    background: #343637;
  }
  & .MuiListItemButton-root {
    :hover {
      background: #454545;
    }
    :active {
      background: #262829;
    }
  }

  ${props => props.theme.breakpoints.down('res768')} {
    margin-top: 8px;
  }
`

const ListItem = styled(ListItemButton)`
  color: white;
  text-transform: none;
  padding-left: 35px;
`

const StyledNextImage = styled(NextImage)`
  width: 20px;
  aspect-ratio: 1;
`

const MyAccountMenuList: FC<Props> = props => {
  const { onClick } = props

  const { t } = useTranslation()

  const { isCurrentPath } = usePath(WhiteList)

  const { account, logout } = useAccountContext()

  const handleOnMenuClick = useCallback(
    async (event: MouseEvent<HTMLElement>, actionKey: MENU_ITEM_ACTION) => {
      switch (actionKey) {
        case MENU_ITEM_ACTION.LOGOUT:
          event.preventDefault()
          await logout()

          break
      }

      if (onClick) {
        onClick(actionKey)
      }
    },
    [logout, onClick]
  )

  const menuItems = useMemo(() => {
    return R.reject((menuItem: MenuItem) => {
      const { hideBy } = menuItem

      switch (hideBy) {
        case MENU_HIDDEN_CONDITION.NO_INVITE_CODE:
          return R.isEmpty(account?.invitation?.code)
      }

      return false
    }, MENU_ITEM_CONFIG)
  }, [account?.invitation?.code])

  return (
    <ListContainer>
      <List>
        {R.map(config => {
          const { alt, src, route, altRoutes, actionKey, translationKey } =
            config

          const selected = isCurrentPath(route, altRoutes)

          return (
            <Link key={`${src}-${translationKey}`} href={config.route}>
              <ListItem
                disableRipple
                selected={selected}
                onClick={(event: MouseEvent<HTMLElement>) => {
                  handleOnMenuClick(event, actionKey)
                }}
              >
                <ListItemIcon>
                  <StyledNextImage alt={alt} src={src} />
                </ListItemIcon>
                <ListItemText primary={t(translationKey)} />
              </ListItem>
            </Link>
          )
        }, menuItems)}
      </List>
    </ListContainer>
  )
}

type Props = {
  onClick?: (actionKey: MENU_ITEM_ACTION) => void
}

export default MyAccountMenuList
