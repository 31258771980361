import { LayoutWhitelist } from '@apeiron/ui'
import { ROUTE } from '@src/constants/route'
import { GIFT_CODE_ROUTE } from '@src/deprecated/giftCode/giftCode'
import * as R from 'ramda'

const SUPPORT_ACCOUNT_HEADER = [
  ROUTE.ACCOUNT_SETTING,
  ROUTE.ADEPTUS_PROGRAM,
  ROUTE.FAVORITE_APOSTLE_DETAIL,
  ROUTE.FAVORITE_APOSTLE_LIST,
  ROUTE.FAVORITE_GODIVERSE_DETAIL,
  ROUTE.FAVORITE_GODIVERSE_LIST,
  ROUTE.FAVORITE_PLANET_DETAIL,
  ROUTE.FAVORITE_PLANET_LIST,
  ROUTE.FAVORITE_STAR_DETAIL,
  ROUTE.FAVORITE_STAR_LIST,
  ROUTE.FAVORITE_STAR_MANAGE,
  ROUTE.INVENTORY_APOSTLE_DETAIL,
  ROUTE.INVENTORY_APOSTLE_HISTORY,
  ROUTE.INVENTORY_APOSTLE_LIST,
  ROUTE.INVENTORY_GODIVERSE_DETAIL,
  ROUTE.INVENTORY_GODIVERSE_LIST,
  ROUTE.INVENTORY_ORBITAL_TRACK_ORBITING,
  ROUTE.INVENTORY_ORBITAL_TRACK_RENTAL,
  ROUTE.INVENTORY_PLANET_DETAIL,
  ROUTE.INVENTORY_PLANET_HISTORY,
  ROUTE.INVENTORY_PLANET_LIST,
  ROUTE.INVENTORY_PLANET_SEED_LIST,
  ROUTE.INVENTORY_STAR_DETAIL,
  ROUTE.INVENTORY_STAR_LIST,
  ROUTE.INVENTORY_STAR_MANAGE,
  ROUTE.INVENTORY_BOUNTY_ACTIVE_LIST,
  ROUTE.INVENTORY_BOUNTY_CLOSED_LIST,
  ROUTE.OTHER_INVENTORY_APOSTLE_LIST,
  ROUTE.OTHER_INVENTORY_GODIVERSE_LIST,
  ROUTE.OTHER_INVENTORY_PLANET_LIST,
  ROUTE.OTHER_INVENTORY_STAR_LIST,
  ROUTE.REWARD_DAILY_CHECK_IN,
  ROUTE.REWARD_LIST,
  ROUTE.REWARD_SHOP,
  ROUTE.REWARD_VESTING,

  /** @deprecated - will be moved to web-store */
  GIFT_CODE_ROUTE
]

const SUPPORT_NO_NAVIGATION_BAR = [ROUTE.CALLBACK_GAME, ROUTE.HOME]

const SUPPORT_NO_FOOTER = [ROUTE.CALLBACK_GAME]

const SUPPORT_NO_INDEX = [
  ROUTE.ACCOUNT_SETTING,
  ROUTE.ADEPTUS_PROGRAM,
  ROUTE.CALLBACK_SSO,
  ROUTE.DEBUG_VERSION,
  ROUTE.FAVORITE_APOSTLE_DETAIL,
  ROUTE.FAVORITE_APOSTLE_LIST,
  ROUTE.FAVORITE_GODIVERSE_DETAIL,
  ROUTE.FAVORITE_GODIVERSE_LIST,
  ROUTE.FAVORITE_PLANET_DETAIL,
  ROUTE.FAVORITE_PLANET_LIST,
  ROUTE.FAVORITE_STAR_DETAIL,
  ROUTE.FAVORITE_STAR_LIST,
  ROUTE.FAVORITE_STAR_MANAGE,
  ROUTE.INVENTORY_APOSTLE_DETAIL,
  ROUTE.INVENTORY_APOSTLE_HISTORY,
  ROUTE.INVENTORY_APOSTLE_LIST,
  ROUTE.INVENTORY_GODIVERSE_DETAIL,
  ROUTE.INVENTORY_GODIVERSE_LIST,
  ROUTE.INVENTORY_ORBITAL_TRACK_ORBITING,
  ROUTE.INVENTORY_ORBITAL_TRACK_RENTAL,
  ROUTE.INVENTORY_PLANET_DETAIL,
  ROUTE.INVENTORY_PLANET_HISTORY,
  ROUTE.INVENTORY_PLANET_LIST,
  ROUTE.INVENTORY_STAR_DETAIL,
  ROUTE.INVENTORY_STAR_LIST,
  ROUTE.INVENTORY_STAR_MANAGE,
  ROUTE.MAINTENANCE,
  ROUTE.NOT_FOUND,
  ROUTE.OTHER_INVENTORY_APOSTLE_LIST,
  ROUTE.OTHER_INVENTORY_GODIVERSE_LIST,
  ROUTE.OTHER_INVENTORY_PLANET_LIST,
  ROUTE.OTHER_INVENTORY_STAR_LIST,
  ROUTE.REWARD_DAILY_CHECK_IN,
  ROUTE.REWARD_LIST,
  ROUTE.REWARD_SHOP,
  ROUTE.REWARD_VESTING
]

const SUPPORT_BOUND_MAX_WIDTH = [
  ROUTE.BATTLE_DEMO_GAME,
  ROUTE.EXPEDITION,
  ROUTE.HOME
]

const prepareWhiteList = (): LayoutWhitelist => {
  let configs: LayoutWhitelist = {}

  const injectConfig = (
    configs: LayoutWhitelist,
    paths: string[],
    field: string,
    value: boolean
  ): LayoutWhitelist => {
    R.forEach((path: string) => {
      const config = R.propOr({}, path, configs)

      const newConfig = R.assoc(field, value, config)

      configs = R.assoc(path, newConfig, configs)
    }, paths)

    return configs
  }

  const routes = Object.values(ROUTE)

  configs = injectConfig(
    configs,
    R.reject(route => R.includes(route, SUPPORT_ACCOUNT_HEADER), routes),
    'noHeader',
    true
  )
  configs = injectConfig(configs, SUPPORT_BOUND_MAX_WIDTH, 'noFullWidth', true)
  configs = injectConfig(configs, SUPPORT_NO_INDEX, 'noIndex', true)
  configs = injectConfig(configs, SUPPORT_NO_NAVIGATION_BAR, 'noNavBar', true)
  configs = injectConfig(configs, SUPPORT_NO_FOOTER, 'noFooter', true)

  return configs
}

export const WhiteList = prepareWhiteList()
