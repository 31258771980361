import { ACCOUNT_TAG_PADDING } from '@apeiron/auth'
import { padNumber } from '@apeiron/common'
import {
  ApeironEllipsisTypography,
  MayNull,
  NextImage,
  noForwardProps,
  SkeletonTypography,
  useAccountContext,
  UserSVG
} from '@apeiron/temp'
import { usePath } from '@apeiron/ui'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Box,
  Button,
  styled,
  SwipeableDrawer,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import LoginButton from '@src/components/account/login/LoginButton'
import AccountMenu from '@src/components/account/menu'
import ShortcutDailyCheckInButton from '@src/components/account/menu/ShortcutDailyCheckInButton'
import ShortcutInventoryButton from '@src/components/account/menu/ShortcutInventoryButton'
import SharedPrimaryNavBar from '@src/components/navigationBar/primary/SharedPrimaryNavBar'
import MuiBadge from '@src/components/share/mui/Badge'
import { DEFAULT_ANIMATE_DURATION, NAV_BAR_CONFIG } from '@src/constants/layout'
import { NAVIGATION_ITEM_CONFIG } from '@src/constants/menu'
import { NotifyType } from '@src/constants/notification'
import { WhiteList } from '@src/constants/page'
import theme from '@src/styles/theme'
import Link from 'next/link'
import * as R from 'ramda'
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StyledButton = styled(Button)`
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  :hover {
    box-shadow: none;
    background: transparent;
  }
`

const StyledTabs = styled(Tabs)`
  flex: 1;
  padding-top: 7px;
  .Mui-disabled {
    opacity: 0.1 !important; // override js on mui
  }
  .MuiTabs-indicator {
    background-color: #c6af8f;

    ${props => props.theme.breakpoints.up('res1024')} {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      height: 100%;
      z-index: -1;
    }
    ${props => props.theme.breakpoints.down('res1024')} {
      width: 100%;
      height: ${NAV_BAR_CONFIG.primary.height}px;
      z-index: -1;
    }
  }
`

const StyledTab = styled(Tab)<{ href: string }>`
  height: 100%;
  min-height: unset;

  ${props => props.theme.breakpoints.up('res1024')} {
    min-width: 170px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    min-width: 100%;
  }
  border-radius: 3px 3px 0px 0px;
  opacity: 1;
  transition: ${DEFAULT_ANIMATE_DURATION}ms;

  :active,
  :hover {
    background-color: #555c7e;
  }
  &.Mui-selected {
    :hover {
      background-color: unset;
    }
  }
`

const NavItemIcon = styled(Box)`
  width: auto;
  aspect-ratio: 1;
  height: 26px;
  position: relative;
  margin-right: 5px;
`

const StyledNextImage = styled(NextImage)`
  width: 100%;
  height: 100%;
`

const NavItemText = styled(Typography)`
  font-size: 18px;
  line-height: 18px;
  color: white;
  text-transform: none;
`

const StyledBadge = styled(MuiBadge)`
  & > .MuiBadge-badge {
    position: relative;
    top: 9px;
    right: 5px;
  }
`

const StyledLoginButton = styled(LoginButton)`
  background: #005bd7;
  margin-left: auto;
  :hover {
    background: #3682eb;
  }
  :focus {
    background: #1f60b8;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    margin-right: auto;
    margin-bottom: 10px;
  }
`

const MyAccountContainer = styled(Box)`
  align-items: center;
  display: flex;
  height: 100%;
  ${props => props.theme.breakpoints.up('res1024')} {
    gap: 2px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    gap: 3px;
  }
`

const MyAccountButton = styled(StyledButton)`
  :hover {
    background-color: #555c7e;
  }
  border-left: 1px solid #3c3d3d;
  display: flex;
  gap: 5px;
  height: 100%;
  text-transform: none;
  width: 218px;
`

const UserIcon = styled(NextImage)`
  aspect-ratio: 1;
  flex-shrink: 0;
  width: 32px;
`

const MyAccountTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  height: 100%;
  justify-content: center;
`

const MyAccountNameContainer = styled(Box)`
  align-items: center;
  gap: 2px;
  display: flex;
  width: 100%;
`

const MyAccountUserName = styled(SkeletonTypography)`
  font-size: 14px;
  line-height: 14px;
  max-width: 115px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  .Custom-Skeleton {
    height: 12px;
    width: 115px;
  }
`

const MyAccountUserID = styled(Typography)`
  color: #b1b2b2;
  font-size: 14px;
  line-height: 14px;
`

const MyAccountWalletAddress = styled(ApeironEllipsisTypography)`
  color: #b1b2b2;
  font-size: 12px;
  line-height: 12px;
  max-width: 192px; // set 192px here as react-middle-ellipsis truncate width is un-predictable
  text-align: left;
  .Custom-Skeleton {
    min-width: 156px;
    height: 10px;
  }
`

const MobileButton = styled(Button)`
  min-width: unset;
  padding: 0px;
`

const DrawerIcon = styled(MenuIcon)`
  color: white;
  font-size: 36px;
`

const CloseDrawerButton = styled(CloseIcon, noForwardProps)<{
  __isDrawerOpened?: boolean
}>`
  position: fixed;
  font-size: 36px;
  right: 20px;
  color: #b1b2b2;
  top: 66px;
  cursor: pointer;
`

const Drawer = styled(SwipeableDrawer)`
  > .MuiDrawer-paper {
    background: #151615;
    gap: 20px;
    max-height: calc(100vh - ${NAV_BAR_CONFIG.primary.height}px);
    padding-bottom: 25px;
    padding-top: ${NAV_BAR_CONFIG.primary.height}px;
    top: ${NAV_BAR_CONFIG.primary.height}px;
  }
  /* Default Appbar has z-index 1100, since the backdrop need to be placed behind the header */
  z-index: 1099;
`

const NavItemList: FC<NavItemProps> = (props: NavItemProps) => {
  const { onClick } = props

  const { t } = useTranslation()

  const { isCurrentPath } = usePath(WhiteList)

  const mobile = useMediaQuery(theme.breakpoints.down('res1024'))

  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (mobile && onClick) {
        onClick(event)
      }
    },
    [mobile, onClick]
  )

  const index = useMemo(() => {
    const indexFound = R.findIndex(config => {
      const { route, altRoutes } = config

      return isCurrentPath(route, altRoutes)
    }, NAVIGATION_ITEM_CONFIG)

    return indexFound === -1 ? false : indexFound
  }, [isCurrentPath])

  return (
    <StyledTabs
      value={index}
      orientation={mobile ? 'vertical' : 'horizontal'}
      variant='scrollable'
    >
      {NAVIGATION_ITEM_CONFIG.map((config, index) => {
        const { alt, route, src, translationKey } = config

        return (
          <StyledTab
            wrapped
            key={route}
            LinkComponent={Link}
            href={route}
            tabIndex={0}
            icon={
              <NavItemIcon>
                <StyledNextImage alt={alt} src={src} />
              </NavItemIcon>
            }
            iconPosition='start'
            label={
              <StyledBadge type={NotifyType.TOPBAR_BATTLE_DEMO + index}>
                <NavItemText>{t(translationKey)}</NavItemText>
              </StyledBadge>
            }
            value={index}
            onClick={handleOnClick}
          />
        )
      })}
    </StyledTabs>
  )
}

const PrimaryNavBar: FC<Props> = () => {
  const { isLoggedIn, setShowLoginPopup, account } = useAccountContext()

  const theme = useTheme()

  const { t } = useTranslation()

  const mobile = useMediaQuery(theme.breakpoints.down('res1024'))

  const [anchorEl, setAnchorEl] = useState<MayNull<Element>>(null)

  const [accountMenuOpen, setAccountMenuOpen] = useState(false)

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const handleOnMobileMenuOpen = useCallback(() => {
    setMobileMenuOpen(true)
    setAccountMenuOpen(false)
  }, [])

  const handleOnAllMenuClose = useCallback(() => {
    setMobileMenuOpen(false)
    setAccountMenuOpen(false)
  }, [])

  const handleOnLoginClick = useCallback(() => {
    setShowLoginPopup(true)
    setAccountMenuOpen(false)
    setMobileMenuOpen(false)
  }, [setShowLoginPopup])

  const handleOnMyAccountClick = useCallback(
    (event: MouseEvent) => {
      setAccountMenuOpen(!accountMenuOpen)
      setMobileMenuOpen(false)
      setAnchorEl(event.currentTarget)
    },
    [accountMenuOpen]
  )

  const loading = R.isEmpty(R.propOr('', 'name', account))

  return (
    <SharedPrimaryNavBar>
      {mobile ? (
        <>
          <MyAccountContainer>
            {isLoggedIn && <ShortcutDailyCheckInButton />}
            <MobileButton onClick={handleOnMobileMenuOpen}>
              <DrawerIcon />
            </MobileButton>
            {isLoggedIn && (
              <MobileButton onClick={handleOnMyAccountClick}>
                <UserIcon src={UserSVG} />
              </MobileButton>
            )}
          </MyAccountContainer>
          {mobileMenuOpen && (
            <CloseDrawerButton onClick={handleOnAllMenuClose} />
          )}
          <Drawer
            anchor='top'
            onOpen={handleOnMobileMenuOpen}
            onClose={handleOnAllMenuClose}
            open={mobileMenuOpen}
          >
            <NavItemList onClick={handleOnAllMenuClose} />
            {!isLoggedIn && <StyledLoginButton onClick={handleOnLoginClick} />}
          </Drawer>
        </>
      ) : (
        <>
          <NavItemList onClick={handleOnAllMenuClose} />
          {isLoggedIn ? (
            <MyAccountContainer>
              <ShortcutDailyCheckInButton />
              <ShortcutInventoryButton />
              <MyAccountButton
                onClick={handleOnMyAccountClick}
                variant='contained'
              >
                <UserIcon src={UserSVG} />
                <MyAccountTextContainer>
                  <MyAccountNameContainer>
                    <MyAccountUserName loading={loading}>
                      {account.name}
                    </MyAccountUserName>
                    <MyAccountUserID>
                      #{padNumber(account.tag, ACCOUNT_TAG_PADDING)}
                    </MyAccountUserID>
                  </MyAccountNameContainer>
                  <MyAccountWalletAddress loading={loading}>
                    {R.isEmpty(account.walletAddress)
                      ? `( ${t('account.no_wallet_address')} )`
                      : account.walletAddress}
                  </MyAccountWalletAddress>
                </MyAccountTextContainer>
              </MyAccountButton>
            </MyAccountContainer>
          ) : (
            <StyledLoginButton onClick={handleOnLoginClick} />
          )}
        </>
      )}
      <AccountMenu
        anchorEl={anchorEl}
        opened={accountMenuOpen}
        onDismiss={handleOnAllMenuClose}
      />
    </SharedPrimaryNavBar>
  )
}

type NavItemProps = {
  onClick: (event: MouseEvent) => void
}

type Props = {}

export default PrimaryNavBar
