import {
  BattleDemoFragmentType,
  BattleDemoRewardSetType
} from '@src/constants/battleDemo'
import {
  BattleDemoAbout,
  BattleDemoAboutCard,
  BattleDemoFragment,
  BattleDemoReward,
  BattleDemoRewardGroup,
  BattleDemoRewardLite,
  BattleDemoRewardSet,
  BattleDemoSeason,
  ClaimableCurrencyDatatype
} from '@src/types/battleDemo'
import * as yup from 'yup'

const fragmentTypeSchema: yup.BaseSchema<BattleDemoFragmentType> = yup
  .mixed()
  .oneOf(Object.values(BattleDemoFragmentType))
  .default(BattleDemoFragmentType.Solar)

const battleDemoRewardSetTypeSchema: yup.BaseSchema<BattleDemoRewardSetType> =
  yup
    .mixed()
    .oneOf(Object.values(BattleDemoRewardSetType))
    .default(BattleDemoRewardSetType.Animus)

const battleDemoRewardLiteV2Schema: yup.SchemaOf<BattleDemoRewardLite> = yup
  .object({
    name: yup.string().default(''),
    value: yup.number().default(0)
  })
  .from('metaKey', 'name')
  .from('qty', 'value')

const battleDemoRewardGroupV2Schema: yup.SchemaOf<BattleDemoRewardGroup> = yup
  .object({
    rankMax: yup.number().default(1),
    rankMin: yup.number().default(1),
    rewardsBasic: yup.array().of(battleDemoRewardLiteV2Schema).default([]),
    rewardsBonus: yup.array().of(battleDemoRewardLiteV2Schema).default([])
  })
  .from('fromRank', 'rankMin')
  .from('toRank', 'rankMax')
  .from('rewards', 'rewardsBasic')

const battleDemoAboutCardSchema: yup.SchemaOf<BattleDemoAboutCard> = yup
  .object({
    description: yup.string().default(''),
    image: yup.string().default(''),
    title: yup.string().default(''),
    imageAspectRatio: yup.number().default(1)
  })
  .from('image_aspect_ratio', 'imageAspectRatio')

const battleDemoRewardSchema: yup.SchemaOf<BattleDemoReward> = yup
  .object({
    image: yup.string().default(''),
    name: yup.string().default(''),
    value: yup.number().default(0),
    claimDatatype: yup.mixed().oneOf(Object.values(ClaimableCurrencyDatatype)),
    source: yup.string().default('')
  })
  .from('metaType.image', 'image')
  .from('metaType.name', 'name')
  .from('metaType.source', 'source')
  .noUnknown(true)

export const battleDemoFragmentsSchema: yup.SchemaOf<BattleDemoFragment> = yup
  .object({
    burn: yup.number().default(0),
    burnRatio: yup.number().default(0),
    id: yup.string().default(''),
    name: yup.string().default(''),
    openSeaUrl: yup.string().default(''),
    total: yup.number().default(0),
    type: fragmentTypeSchema
  })
  .camelCase()

export const battleDemoAboutSchema: yup.SchemaOf<BattleDemoAbout> = yup
  .object({
    apeironTitle: yup.string().default(''),
    content: yup.array().of(battleDemoAboutCardSchema).default([]),
    title: yup.string().default('')
  })
  .camelCase()

export const battleDemoPvESeasonSchema: yup.SchemaOf<BattleDemoSeason> = yup
  .object({
    active: yup.boolean().default(false),
    end: yup.string().default(''),
    icon: yup.string().nullable().default(null),
    id: yup.string().default(''),
    name: yup.string().default(''),
    start: yup.string().default(''),
    value: yup.string().default(''),
    rewardGroups: yup
      .array()
      .of(battleDemoRewardGroupV2Schema)
      .nullable()
      .default(null)
  })
  .from('isActive', 'active')
  .from('leaderboardKey', 'id', true)
  .from('leaderboardKey', 'value')
  .from('metaKey', 'name')
  .from('seasonalReward', 'rewardGroups')
  .noUnknown(true)

export const battleDemoPvPSeasonSchema: yup.SchemaOf<BattleDemoSeason> = yup
  .object({
    active: yup.boolean().default(false),
    end: yup.string().default(''),
    icon: yup.string().nullable().default(null),
    id: yup.string().default(''),
    name: yup.string().default(''),
    start: yup.string().default(''),
    value: yup.string().default(''),
    rewardGroups: yup
      .array()
      .of(battleDemoRewardGroupV2Schema)
      .nullable()
      .default(null)
  })
  .from('isActive', 'active')
  .from('season', 'id', true)
  .from('metaKey', 'name')
  .from('season', 'value')
  .from('seasonalReward', 'rewardGroups')
  .noUnknown(true)

export const battleDemoRewardSetSchema: yup.SchemaOf<BattleDemoRewardSet> = yup
  .object({
    items: yup.array().of(battleDemoRewardSchema).default([]),
    type: battleDemoRewardSetTypeSchema
  })
  .from('claimType', 'type')
  .from('data', 'items')
  .noUnknown(true)
